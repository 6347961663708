import { createContext } from "preact";
import { useState } from 'preact/hooks';

const cities = [
  {
    id: 'aparecida-de-goiania',
    displayName: 'Aparecida de Goiânia',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1750,
    price: 1400
  },
  {
    id: 'barueri',
    displayName: 'Barueri',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1560
  },
  {
    id: 'belo-horizonte',
    displayName: 'Belo Horizonte',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1700
  },
  {
    id: 'canoas',
    displayName: 'Canoas',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1460
  },
  {
    id: 'campinas',
    displayName: 'Campinas',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1960,
    price: 1680
  },
  {
    id: 'caxias-do-sul',
    displayName: 'Caxias do Sul',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1720
  },
  {
    id: 'colombo',
    displayName: 'Colombo',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1600
  },
  {
    id: 'curitiba',
    displayName: 'Curitiba',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1700
  },
  {
    id: 'diadema',
    displayName: 'Diadema',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1600
  },
  {
    id: 'goiania',
    displayName: 'Goiânia',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1750,
    price: 1400
  },
  {
    id: 'guarulhos',
    displayName: 'Guarulhos',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1560
  },
  {
    id: 'joinville',
    displayName: 'Joinville',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1480
  },
  {
    id: 'londrina',
    displayName: 'Londrina',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1460
  },
  {
    id: 'maringa',
    displayName: 'Maringá',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1400
  },
  {
    id: 'mogi-das-cruzes',
    displayName: 'Mogi das Cruzes',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1500
  },
  {
    id: 'novo-hamburgo',
    displayName: 'Novo Hamburgo',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1420
  },
  {
    id: 'osasco',
    displayName: 'Osasco',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1560
  },
  {
    id: 'pelotas',
    displayName: 'Pelotas',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1600,
    price: 1600
  },
  {
    id: 'porto-alegre',
    displayName: 'Porto Alegre',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1460
  },
  {
    id: 'rio-grande',
    displayName: 'Rio Grande',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1650,
    price: 1340
  },
  {
    id: 'sao-paulo',
    displayName: 'São Paulo',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 2030
  },
  {
    id: 'sao-jose-dos-campos',
    displayName: 'São José dos Campos',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1540
  },
  {
    id: 'santa-maria',
    displayName: 'Santa Maria',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1500
  },
  {
    id: 'santo-andre',
    displayName: 'Santo André',
    feePerHour: 100,
    fee: 4100,
    pricePerHour: 1760,
    price: 1500
  },
]

const DonamaidContext = createContext([{}, () => {}]);

const DonamaidContextProvider = (props) => {
  const [state, setState] = useState({
    cities: cities,
    selectedCity: 'Porto Alegre',
    popupDisplayed: false
  });

  return (
    <DonamaidContext.Provider value={[state, setState]}>
      { props.children }
    </DonamaidContext.Provider>
  )
}

export { DonamaidContext, DonamaidContextProvider };